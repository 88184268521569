import { useEffect } from "react"

import { useDrag, useDragLayer } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"
import { Icon } from "@blueprintjs/core"

import { ThumbnailPreview } from "components/pages"
import { ThumbnailPreviewIcon } from "components/item-detail/ui"

import PageLabel from "./PageLabel"
import WithLabelForItemType from "./WithLabelForItemType"

export const dragTypeForPage = {
  inserted_page: "EXECUTED_SIGNATURE_PAGE",
  document: "SIGNATURE_PAGE",
  attachment: "ATTACHMENT",
  instapage: "INSTAPAGE",
  instapagev2: "INSTAPAGEV2",
}

const NullPage = {}

type PagePreviewProps = {
  index?: number
  isOver?: boolean
  metaPage?: Record<string, any>
  onCancel: () => void
  onToggleSignaturePageType?: () => void
  pageType?: string
  position?: number
  url?: string
}

export default function PagePreview({
  index,
  isOver,
  metaPage = NullPage,
  onCancel,
  onToggleSignaturePageType,
  pageType,
  url,
  position,
}: PagePreviewProps) {
  const forSigning = metaPage.forSigning || metaPage.isExecutedSignaturePage
  const isDocumentSignaturePage =
    metaPage.type === "document" && metaPage.isSignaturePage

  const [{ isDragging }, dragRef, preview] = useDrag({
    item: {
      index,
      type: dragTypeForPage[metaPage.type] || "NONE",
      metaPage,
      pageType,
      position,
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end: (_, monitor) => {
      if (!monitor.didDrop()) {
        onCancel()
      }
    },
  })

  const { item: draggingItem } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
  }))

  useEffect(() => {
    preview(getEmptyImage())
  }, [])

  const metaPageValues = {
    inserted_page: {
      icon: "draw",
      border: "blue",
      rotate: true,
      svg: "/static/page-loading-3-borderless.svg",
    },
    attachment: {
      icon: "paperclip",
      border: "blue",
      pageAmount: metaPage.items?.map((item) => item.pages?.length),
      pageTagText: "Number of pages:",
      pageTagBg: "bg-[#E3ECFC]",
      pageTagColor: "text-[#4580E6]",
      svg: "/static/page-loading-4-borderless.svg",
    },
    instapage: {
      icon: "draw",
      border: "green",
      rotate: true,
      pageAmount: metaPage.assignments?.length,
      pageTagText: "Number of InstaPages:",
      pageTagBg: "bg-[#D9F2DB]",
      pageTagColor: "text-[#29A634]",
      svg: "/static/page-loading-2-borderless.svg",
    },
    instapagev2: {
      icon: "draw",
      border: "green",
      rotate: true,
      pageAmount: metaPage.assignments?.length,
      pageTagText: "Number of InstaPages:",
      pageTagBg: "bg-[#D9F2DB]",
      pageTagColor: "text-[#29A634]",
      svg: "/static/page-loading-2-borderless.svg",
    },
  }

  const canDropItemOnMe = !forSigning && !metaPage?.type

  return (
    <div className="relative flex flex-shrink-0 flex-col">
      <>
        <ThumbnailPreview
          border={
            (forSigning && metaPage.type !== "inserted_page") ||
            (isOver &&
              canDropItemOnMe &&
              draggingItem?.metaPage.type === "document")
              ? "green"
              : metaPage.type === "attachment" ||
                  metaPage.type === "inserted_page"
                ? metaPageValues[metaPage.type]?.border
                : null
          }
          className={
            isOver && draggingItem?.metaPage.type === "document"
              ? "shadow-lg"
              : ""
          }
          src={metaPageValues[metaPage.type]?.svg || url}
          draggable={false}
          shadow={false}
          height="350px"
          width="260px"
        />
        {metaPage !== NullPage && !isDocumentSignaturePage && (
          <ThumbnailPreviewIcon
            className="absolute right-2 top-2"
            height="36px"
            width="36px"
          >
            <Icon
              icon={metaPageValues[metaPage.type]?.icon}
              className={metaPageValues[metaPage.type]?.rotate && "rotate-90"}
              size={20}
            />
          </ThumbnailPreviewIcon>
        )}

        {metaPageValues[metaPage.type]?.pageAmount > 0 && (
          <div
            className={`absolute bottom-16 left-1/2 w-fit -translate-x-1/2 whitespace-nowrap rounded px-1.5 py-0.5 text-center text-sm ${metaPageValues[
              metaPage.type
            ]?.pageTagBg} ${metaPageValues[metaPage.type]?.pageTagColor}`}
          >
            {`${metaPageValues[metaPage.type]?.pageTagText} ${metaPageValues[
              metaPage.type
            ]?.pageAmount}`}
          </div>
        )}
      </>
      {(forSigning || metaPage.type === "attachment") && (
        <div className="mt-3 flex justify-center">
          <WithLabelForItemType item={metaPage}>
            <PageLabel
              ref={dragRef}
              isDragging={isDragging}
              type={metaPage.type}
              onTogglePageType={() => onToggleSignaturePageType(metaPage)}
            />
          </WithLabelForItemType>
        </div>
      )}
    </div>
  )
}
