type RenderablePage = {
  index?: number
  pageIndex?: number
  pageType: string
  position?: number
  metaPage?: Record<string, any>
  url?: string
}

export default function renderablePages(
  pages: RenderablePage[],
  placeholders: RenderablePage[],
  isInitialState?: boolean
) {
  const isPlaceholderItem = isInitialState
    ? placeholders.length === 0 ||
      (placeholders.every((p) => p.metaPage?.type === "instapagev2") &&
        placeholders[0]?.pageIndex === 0)
    : false

  let allPages = []
  if (isPlaceholderItem) {
    let pagePlaceholders = placeholders.map((p, position) => ({
      ...p,
      pageIndex: pages.length,
      position: position,
    }))
    allPages = pages.concat(pagePlaceholders)
  } else {
    allPages = pages.reduce((renderPages: RenderablePage[], page, index) => {
      const pagePlaceholders = placeholders
        .filter((p) => p.pageIndex === index)
        .map((p, position) => ({ ...p, position }))

      const isLastPage = index === pages.length - 1

      if (pagePlaceholders.length && !isLastPage) {
        return renderPages.concat(pagePlaceholders, page)
      }

      if (isLastPage) {
        let lastPagePlacedholders = isLastPage
          ? placeholders
              .filter((p) => p.pageIndex === pages.length)
              .map((p, position) => ({ ...p, position }))
          : []

        return renderPages.concat(pagePlaceholders, page, lastPagePlacedholders)
      }

      renderPages.push(page)

      return renderPages
    }, [])
  }

  return allPages
}
