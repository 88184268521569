import { ForwardedRef, forwardRef, ReactNode } from "react"

import cx from "classnames"

function BarIcon() {
  return (
    <svg
      viewBox="0 0 24 24"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="4" r="2" fill="currentColor" />
      <line x1="12" y1="4" x2="12" y2="20" stroke="currentColor" />
      <circle cx="12" cy="20" r="2" fill="currentColor" />
    </svg>
  )
}

type MetaPageLabelProps = {
  label?: ReactNode
  isDragging?: boolean
  type?: string
  onTogglePageType?: () => void
}

function MetaPageLabel(
  { label, isDragging, type, onTogglePageType }: MetaPageLabelProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const classes = cx(
    "w-auto h-10 px-2 py-2 rounded shadow-lg text-white cursor-grab text-center hover:z-50 flex items-center max-w-[250px] overflow-hidden",
    "group",
    {
      "bg-forest-4":
        type === "document" || type === "instapage" || type === "instapagev2",
      "bg-[#4580E6]": type === "attachment" || type === "inserted_page",
      "w-20 truncate": type !== "document",
    }
  )

  const btnClasses = cx(
    "ml-1 w-6 h-6 rounded text-white p-px flex items-center justify-center",
    {
      "bg-sky-700 hover:bg-sky-800": type === "inserted_page",
      "bg-green-700 hover:bg-green-800": type === "document",
    }
  )

  return (
    <div
      ref={ref}
      className={classes}
      style={{
        opacity: isDragging ? "0" : "1",
      }}
    >
      <div className="truncate">{label}</div>
      {/* NOTE Temp hiding this feature - CMM */}
      {false && (type === "document" || type === "inserted_page") && (
        <button onClick={onTogglePageType} className={btnClasses}>
          {type === "document" ? (
            <BarIcon />
          ) : (
            <div className="h-3 w-3 rounded-sm border border-white" />
          )}
        </button>
      )}
    </div>
  )
}

export default forwardRef(MetaPageLabel)
