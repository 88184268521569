import React, { useEffect } from "react"

import { useDocumentUpload } from "app/document-uploads"

type VersionUploaderProps = {
  children?: React.ReactNode
  files?: any[]
  transactionId: string
  isAddingFromDMS?: boolean
}

export default function VersionUploader({
  children,
  files,
  isAddingFromDMS,
  transactionId,
}: VersionUploaderProps) {
  const [uploads, uploadFile, clearUploads] = useDocumentUpload({
    transactionId,
    inQueue: false,
    isFromDMS: isAddingFromDMS,
    multiple: false,
    shouldPauseOnComplete: true,
  })

  useEffect(() => {
    files?.forEach(uploadFile)
  }, [files])

  useEffect(() => () => clearUploads(), [])

  if (uploads.length > 0) {
    return React.cloneElement(React.Children.only(children), {
      version: uploads[0],
    })
  }

  if (isAddingFromDMS) {
    return children
  }

  return null
}
