import { useEffect, useRef, useState } from "react"
import { Button, Intent, Radio, RadioGroup } from "@blueprintjs/core"

import { cEventTrack } from "app/user-tracking/index"

import DraggablePageLabel from "./DraggablePageLabel"
import DroppableContainer from "./DroppableContainer"
import PlaceholderBar from "./PlaceholderBar"
import PagePreview from "./PagePreview"
import VersionLoading from "./VersionLoading"
import renderablePages from "./renderable-pages"

type VersionPageIdentifierProps = {
  item: Record<string, any>
  version?: Record<string, any>
  user?: Record<string, any>
  onClose?: () => void
  onClearPendingDocument?: () => void
  onMovePage?: () => void
  onToggleSignaturePageType?: () => void
  onStatusChange?: () => void
  onSubmit?: () => void
  selectedStatusValue?: string
  pages?: any[]
  placeholders?: any[]
  isClearingPendingDocument?: boolean
  isSubmitting?: boolean
}

export default function VersionPageIdentifier({
  item,
  isClearingPendingDocument = false,
  isSubmitting = false,
  onClose = () => null,
  onClearPendingDocument = () => null,
  onMovePage = () => null,
  onToggleSignaturePageType = () => null,
  onStatusChange = () => null,
  onSubmit = () => null,
  selectedStatusValue,
  pages = [],
  placeholders = [],
  version = {},
  user,
}: VersionPageIdentifierProps) {
  const firstMetaPage = useRef()
  const [dropIndex, setDropIndex] = useState<number | null>(null)
  const [isInitialPageState, setIsInitialPageState] = useState(true)

  useEffect(() => {
    const scroller = setTimeout(() => {
      if (firstMetaPage.current && version.status === "CONVERSION_COMPLETE") {
        firstMetaPage.current.scrollIntoView({ behavior: "smooth" })
      }
    }, 200)
    return () => clearTimeout(scroller)
  }, [version.status])

  if (version.status !== "CONVERSION_COMPLETE") {
    return <VersionLoading version={version} onClose={onClose} />
  }

  function handleSetHoverIndex(index: number) {
    setDropIndex(index)
  }

  function clearDropUI() {
    setDropIndex(null)
  }

  function handleDrop(item) {
    setIsInitialPageState(false)
    onMovePage(item, dropIndex)
    clearDropUI()
  }

  function handleCancel(item) {
    if (dropIndex !== null && item) {
      handleDrop(item)
      return
    }

    clearDropUI()
  }

  let firstMetaIndex = 0
  firstMetaIndex = pages.findIndex((p) => !!p.metaPage) || 0
  const firstPlaceholderPageIndex = placeholders[0]?.pageIndex || 0

  if (
    firstPlaceholderPageIndex !== 0 &&
    firstPlaceholderPageIndex < firstMetaIndex
  ) {
    firstMetaIndex = firstPlaceholderPageIndex
  }

  const allPages = renderablePages(pages, placeholders, isInitialPageState)

  const isPlaceholderItemWithNoPages = item.pages.length === 0
  const isPlaceholderWithIPPages =
    item.pages.length > 0 && item.pages.every((p) => p.type === "instapagev2")

  return (
    <div>
      {isPlaceholderItemWithNoPages && (
        <div className="pt-3">
          <RadioGroup
            onChange={onStatusChange}
            className="px-5"
            inline
            selectedValue={selectedStatusValue}
          >
            <Radio label="Non-executable" value="non_executable" />
            <Radio label="To be signed" value="to_be_signed" />
            <Radio label="Fully executed" value="fully_executed" />
          </RadioGroup>
          {isPlaceholderWithIPPages && (
            <div className="font-semibold text-lg px-5 pt-5">
              Upload a document to the {item?.name}
            </div>
          )}
        </div>
      )}
      {!isPlaceholderItemWithNoPages && (
        <p className="px-5 pt-3">
          {isPlaceholderWithIPPages ? (
            <>
              We&apos;ve added your InstaPages to the end of this document. If
              you need them in a different location, drag and drop the
              &apos;InstaPage&apos; label to the correct position in the
              document.
            </>
          ) : (
            <>
              Sometimes new versions are a different length than the original.
              Drag each of the signature page and attachment labels into the
              proper location in the new version.
            </>
          )}
        </p>
      )}
      <DraggablePageLabel />
      <div className="flex overflow-x-scroll px-3 py-4">
        {allPages.map(({ metaPage, url, pageType, position }, index) => (
          <div
            className="relative flex flex-shrink-0"
            key={url + metaPage?.id}
            ref={index === firstMetaIndex ? firstMetaPage : null}
          >
            <PlaceholderBar isOver={dropIndex === index} />
            <DroppableContainer
              index={index}
              setHoverIndex={handleSetHoverIndex}
              onDrop={handleDrop}
              metaPage={metaPage}
              pageType={pageType}
            >
              <PagePreview
                onCancel={handleCancel}
                onToggleSignaturePageType={onToggleSignaturePageType}
                isOver={dropIndex === index}
                index={index}
                metaPage={metaPage}
                pageType={pageType}
                url={url}
                position={position}
              />
            </DroppableContainer>
          </div>
        ))}
        <div className="relative flex flex-shrink-0">
          {dropIndex === allPages.length && <PlaceholderBar isOver />}
        </div>
      </div>
      <div className="mr-1 flex rounded-b-sm bg-gray-50 p-4">
        {item.pendingVersionDocumentId && (
          <Button
            className="mr-auto"
            disabled={isClearingPendingDocument}
            minimal
            onClick={onClearPendingDocument}
            text="Ignore this document version"
          />
        )}
        <Button
          minimal
          disabled={isSubmitting}
          className="ml-auto mr-3"
          onClick={onClose}
        >
          <div className="text-blue-9">Cancel</div>
        </Button>
        <Button
          disabled={isSubmitting}
          loading={isSubmitting}
          intent={Intent.PRIMARY}
          // TODO: Make text upload for placeholders
          text={
            isPlaceholderItemWithNoPages || isPlaceholderWithIPPages
              ? "Upload"
              : "Add version"
          }
          onClick={() => {
            onSubmit()
            cEventTrack(user, "NEW_VERSION_UPLOADED")
          }}
        />
      </div>
    </div>
  )
}
