import * as React from "react"

import { Button, Icon } from "@blueprintjs/core"
import { v4 as uuid } from "uuid"

import DmsSystem from "app/dms-system"

import { AuthContext } from "helpers/contexts"
import { useTransactionIdParam } from "helpers/params"

import DMSApplicationModal from "features/dms/application"
import { useItemDetail } from "features/item/api"

import DocumentUploadCTA from "components/uploads/DocumentUploadCTA"
import DraggableContainer, {
  DroppableContainer,
  DroppableMessage,
} from "components/dnd/DraggableContainer"
import DocumentVersionIcon from "icons/document-version.svg"

import VersionForm from "./VersionForm"
import VersionUploader from "./VersionUploader"
import VersionPageIdentifier from "./VersionPageIdentifier"
import SAModal from "components/modals/SAModal"
import { itemForUI } from "models/UIItem"

type VersionManagerProps = {
  newVersionId?: string
  itemId: string
  onClose?: () => void
}

export default function VersionManager({
  newVersionId,
  itemId,
  onClose = () => null,
}: VersionManagerProps) {
  const transactionId = useTransactionIdParam()
  const { data: itemData } = useItemDetail(transactionId, itemId)
  const [isNativeDMSAppOpen, setIsNativeDMSAppOpen] = React.useState(false)
  const [DMSFiles, setDMSFiles] = React.useState([])
  const { user } = React.useContext(AuthContext)
  const [isDocUploaded, setIsDocUploaded] = React.useState(false)

  const item = React.useMemo(() => itemForUI(itemData), [itemData?.id])

  function handleOnClickFromDMS() {
    if (user.net_docs_config || user.imanage_configs?.length > 0) {
      setIsNativeDMSAppOpen(true)
    } else {
      const docId = uuid()
      setDMSFiles([
        {
          id: docId,
          status: "WAITING_ON_DMS",
        },
      ])
      DmsSystem.open({
        fusionUrl: String(user?.fusion_url),
        transaction_uuid: transactionId,
        user_uuid: user?.uuid,
        upload_type: "new_version",
        document_uuid: docId,
      })
    }
  }

  function handleDMSPendingUploads([dmsDoc = {}] = []) {
    setDMSFiles([{ ...dmsDoc, status: "SELECTED_FROM_DMS" }])
  }

  React.useEffect(() => {
    if (newVersionId) {
      handleDMSPendingUploads([{ id: newVersionId }])
    }
  }, [newVersionId])

  const isAddingFromDMS = DMSFiles.length > 0

  const pendingVersionFiles = React.useMemo(
    () => [{ id: item?.pendingVersionDocumentId }],
    [item?.pendingVersionDocumentId]
  )

  const isPlaceholderWithNoPages = item?.pages && item?.pages.length === 0

  const isPlaceholderWithIPPages =
    item?.pages &&
    item?.pages.length > 0 &&
    item?.pages.every((p) => p.type === "instapagev2")

  const getTitle = (files: any[]) => {
    if (item?.pendingVersionDocumentId) {
      return `Confirm page locations in the new version of ${item?.name}`
    }

    if (isAddingFromDMS && !isDocUploaded) {
      return `Confirm page locations in the new version of ${item?.name}`
    }

    if (
      (isPlaceholderWithIPPages && !isPlaceholderWithNoPages) ||
      (isPlaceholderWithNoPages && !isDocUploaded)
    ) {
      return `Add document to the ${item?.name}`
    }

    if (
      (files.length > 0 || isAddingFromDMS) &&
      isPlaceholderWithNoPages &&
      isDocUploaded
    ) {
      return (
        <div className="font-semibold text-lg px-5">
          Set document status <span className="text-red-5">*</span>
        </div>
      )
    }

    return `Select a new version of the ${item?.name}`
  }

  if (!item) return null

  return (
    <DraggableContainer initialIsDroppable>
      {({ isDroppable, isDragging, onOpen, rootProps, renderInput, files }) => (
        <SAModal
          onClose={onClose}
          title={getTitle(files)}
          icon={<DocumentVersionIcon />}
          body={
            <div className="w-[80vw]">
              <DroppableContainer
                {...rootProps}
                isDroppable={isDroppable}
                isDragging={isDragging}
              >
                {isDroppable && renderInput()}

                {isDragging && (
                  <DroppableMessage>
                    <Icon icon="upload" size={40} /> Drop documents to add a new
                    version
                  </DroppableMessage>
                )}
                {item?.pendingVersionDocumentId ? (
                  <VersionUploader
                    files={pendingVersionFiles}
                    isAddingFromDMS
                    transactionId={transactionId}
                  >
                    <VersionForm
                      item={item}
                      onClose={onClose}
                      onDocUploadComplete={() => setIsDocUploaded(true)}
                    >
                      <VersionPageIdentifier item={item} user={user} />
                    </VersionForm>
                  </VersionUploader>
                ) : files.length > 0 || isAddingFromDMS ? (
                  <VersionUploader
                    files={isAddingFromDMS ? DMSFiles : files}
                    isAddingFromDMS={isAddingFromDMS}
                    transactionId={transactionId}
                  >
                    <VersionForm
                      item={item}
                      onClose={onClose}
                      onDocUploadComplete={() => setIsDocUploaded(true)}
                    >
                      <VersionPageIdentifier item={item} user={user} />
                    </VersionForm>
                  </VersionUploader>
                ) : (
                  <>
                    <DocumentUploadCTA
                      body={`Drag and drop a ${
                        isPlaceholderWithIPPages || isPlaceholderWithNoPages
                          ? "document here or select a file"
                          : "new version here or click to select files"
                      } from your document management system or computer.`}
                      onClickFromComputer={onOpen}
                      onClickFromDMS={handleOnClickFromDMS}
                      orientation="horizontal"
                      title=""
                    />
                    {isNativeDMSAppOpen && (
                      <DMSApplicationModal
                        onClose={() => setIsNativeDMSAppOpen(false)}
                        onAddPendingDMSDocs={handleDMSPendingUploads}
                        multipleFiles={false}
                      />
                    )}
                    <div className="mt-1 flex">
                      <Button
                        minimal
                        className="mx-5 mb-4 ml-auto"
                        onClick={onClose}
                      >
                        <div className="text-blue-9">Cancel</div>
                      </Button>
                    </div>
                  </>
                )}
              </DroppableContainer>
            </div>
          }
        ></SAModal>
      )}
    </DraggableContainer>
  )
}
